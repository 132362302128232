import { throttle } from "lodash";
const pageNavigationSelect = document.querySelector("[data-behaviour='page-navigation-select']");
const pageNavigationLinks = document.querySelectorAll("[data-behaviour='page-navigation-link']");
const pageNavigationSections = document.querySelectorAll(
  "[data-behaviour='page-navigation-section']"
);

if (pageNavigationLinks.length > 0 && pageNavigationSections.length > 0) {
  window.addEventListener(
    "scroll",
    throttle(() => {
      pageNavigationSections.forEach((pageNavigationSection) => {
        let sectionOffset = 138; // Fixed header and account for section padding mobile
        if (window.innerWidth <= 1024) {
          sectionOffset += 48;
        }
        const sectionPosition = pageNavigationSection.getBoundingClientRect();
        const sectionVisible = sectionPosition.top < sectionOffset;

        const activePageNavigationLink = document.querySelector(
          `[data-behaviour='page-navigation-link'][data-target='${pageNavigationSection.getAttribute(
            "id"
          )}']`
        );

        if (activePageNavigationLink !== null) {
          if (sectionVisible) {
            pageNavigationLinks.forEach((pageNavigationLink) => {
              pageNavigationLink.classList.remove("active");
            });
            activePageNavigationLink.classList.add("active");
          }
        }

        if (pageNavigationSelect !== null) {
          const activePageNavigationSelect = document.querySelector(
            `[data-behaviour='page-navigation-select'] [data-behaviour="page-navigation-option-target"][data-target='${pageNavigationSection.getAttribute(
              "id"
            )}']`
          );
          if (activePageNavigationSelect !== null && sectionVisible) {
            activePageNavigationSelect.dataset.trigger = "false";
            pageNavigationSelect.value = activePageNavigationSelect.value;
            activePageNavigationSelect.dataset.trigger = "true";
          }
        }
      });
    }, 300)
  );
}

if (pageNavigationSelect !== null) {
  pageNavigationSelect.addEventListener("change", (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    if (selectedOption !== null) {
      if (
        selectedOption.dataset.behaviour === "page-navigation-option-target" &&
        selectedOption.dataset.target !== ""
      ) {
        if (selectedOption.dataset.trigger === "true") {
          const anchorLocation = document.querySelector(
            `[data-behaviour='page-navigation-section'][id='${selectedOption.dataset.target}']`
          );
          if (anchorLocation !== null) {
            anchorLocation.scrollIntoView({ behavior: "smooth" });
          }
        }
      } else if (
        selectedOption.dataset.behaviour === "page-navigation-option-href" &&
        selectedOption.dataset.href !== ""
      ) {
        window.location.href = selectedOption.dataset.href;
      }
    }
  });
}
