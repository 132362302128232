export const ALGOLIA_FACET = "searchCategory";

export const FACETS = [
  {
    name: "hasUrl",
    hidden: true,
    values: [],
  },
  {
    name: "elementStatus",
    hidden: true,
    values: [],
  },
  {
    name: "siteId",
    hidden: true,
    values: [],
  },
  {
    name: "startDate",
    hidden: true,
  },
];

export const DISJUNCTIVE_FACETS = [
  {
    displayTitle: "Section",
    name: "section",
    type: "checkboxFacet",
    displayOrder: 1,
    values: [],
  },
  {
    displayTitle: "Level of study",
    name: "levelOfStudy",
    type: "checkboxFacet",
    displayOrder: 2,
    values: [],
  },
  {
    name: "elementStatus",
    hidden: true,
    values: [],
  },
];

export const NUMERIC_RANGE_REFINEMENTS = [];
