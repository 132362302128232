const courseTabs = document.querySelectorAll("[data-behaviour='course-tabs']");
if (courseTabs.length > 0) {
  courseTabs.forEach((courseTab) => {
    const courseTabNavs = courseTab.querySelectorAll("[data-behaviour='course-tab-nav']");
    const courseTabContents = courseTab.querySelectorAll("[data-behaviour='course-tab-content']");
    if (courseTabNavs.length > 0 && courseTabContents.length > 0) {
      courseTabNavs.forEach((courseTabNav) => {
        courseTabNav.addEventListener("click", (event) => {
          event.preventDefault();
          const activeTabContent = courseTab.querySelector(
            `[data-behaviour='course-tab-content'][data-target='${courseTabNav.dataset.target}']`
          );
          if (activeTabContent !== null) {
            courseTabNavs.forEach((tabNav) => {
              tabNav.classList.remove("active");
            });
            courseTabContents.forEach((tabContent) => {
              tabContent.classList.remove("active");
            });
            activeTabContent.classList.add("active");
            courseTabNav.classList.add("active");
          }
        });
      });
    }
  });
}

const courseHeroSticky = document.querySelector("[data-behaviour='course-hero-sticky']");
if (courseHeroSticky !== null) {
  const observer = new IntersectionObserver(
    function (entries) {
      entries.forEach((entry) => {
        if (entry.target && entry.target.dataset.behaviour === "course-hero-sticky") {
          if (entry.isIntersecting) {
            document.body.classList.add("course-hero-sticky");
          } else {
            document.body.classList.remove("course-hero-sticky");
          }
        }
      });
    },
    {
      rootMargin: "0px",
    }
  );

  observer.observe(courseHeroSticky);
}
